// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loop-black-js": () => import("./../src/pages/loop-black.js" /* webpackChunkName: "component---src-pages-loop-black-js" */),
  "component---src-pages-loop-white-js": () => import("./../src/pages/loop-white.js" /* webpackChunkName: "component---src-pages-loop-white-js" */),
  "component---src-pages-zoom-white-js": () => import("./../src/pages/zoom-white.js" /* webpackChunkName: "component---src-pages-zoom-white-js" */)
}

